import {createSinglePathSVG} from './TEMPLATE'

export const EmojiSad_Stroke2_Corner0_Rounded = createSinglePathSVG({
  path: 'M6.343 6.343a8 8 0 1 1 11.314 11.314A8 8 0 0 1 6.343 6.343ZM19.071 4.93c-3.905-3.905-10.237-3.905-14.142 0-3.905 3.905-3.905 10.237 0 14.142 3.905 3.905 10.237 3.905 14.142 0 3.905-3.905 3.905-10.237 0-14.142Zm-3.537 9.535a5 5 0 0 0-7.07 0 1 1 0 1 0 1.413 1.415 3 3 0 0 1 4.243 0 1 1 0 0 0 1.414-1.415ZM16 9.5c0 .828-.56 1.5-1.25 1.5s-1.25-.672-1.25-1.5.56-1.5 1.25-1.5S16 8.672 16 9.5ZM9.25 11c.69 0 1.25-.672 1.25-1.5S9.94 8 9.25 8 8 8.672 8 9.5 8.56 11 9.25 11Z',
})

export const EmojiSmile_Stroke2_Corner0_Rounded = createSinglePathSVG({
  path: 'M17.657 6.343A8 8 0 1 0 6.343 17.657 8 8 0 0 0 17.657 6.343ZM4.929 4.93c3.905-3.905 10.237-3.905 14.142 0 3.905 3.905 3.905 10.237 0 14.142-3.905 3.905-10.237 3.905-14.142 0-3.905-3.905-3.905-10.237 0-14.142Zm3.536 9.192a1 1 0 0 1 1.414 0 3 3 0 0 0 4.243 0 1 1 0 0 1 1.414 1.415 5 5 0 0 1-7.071 0 1 1 0 0 1 0-1.415ZM10.5 9.5c0 .828-.56 1.5-1.25 1.5S8 10.328 8 9.5 8.56 8 9.25 8s1.25.672 1.25 1.5ZM16 9.5c0 .828-.56 1.5-1.25 1.5s-1.25-.672-1.25-1.5.56-1.5 1.25-1.5S16 8.672 16 9.5Z',
})

export const EmojiArc_Stroke2_Corner0_Rounded = createSinglePathSVG({
  path: 'M12 4a8 8 0 1 0 0 16 8 8 0 0 0 0-16ZM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm8-5a1 1 0 0 1 1 1v3a1 1 0 1 1-2 0V8a1 1 0 0 1 1-1Zm4 0a1 1 0 0 1 1 1v3a1 1 0 1 1-2 0V8a1 1 0 0 1 1-1Zm-5.894 7.803a1 1 0 0 1 1.341-.447c1.719.859 3.387.859 5.106 0a1 1 0 1 1 .894 1.788c-2.281 1.141-4.613 1.141-6.894 0a1 1 0 0 1-.447-1.341Z',
})

export const EmojiColonThree_Stroke2_Corner0_Rounded = createSinglePathSVG({
  path: 'm 12,4 c -4.418281,0 -7.9999965,3.5817221 -7.9999965,8 0,4.418278 3.5817155,8 7.9999965,8 4.418281,0 7.999997,-3.581722 7.999997,-8 C 19.999997,7.5817221 16.418281,4 12,4 Z M 2,12 C 2,6.477 6.477,2 12,2 17.523,2 22,6.477 22,12 22,17.523 17.523,22 12,22 6.477,22 2,17.523 2,12 Z m 8,-5 c 0.552285,0 1,0.4477153 1,1 v 3 c 0,1.333333 -2,1.333333 -2,0 V 8 C 9,7.4477153 9.4477153,7 10,7 Z m 4,0 c 0.552285,0 1,0.4477153 1,1 v 3 c 0,1.333333 -2,1.333333 -2,0 V 8 c 0,-0.5522847 0.447715,-1 1,-1 z m -4.5000002,8.45 c 0,1 1.5000002,1 1.5000002,0 0,-0.55 0.45,-1 1,-1 0.55,0 1,0.45 1,1 0,1 1.6,1 1.6,0 0,-0.55 0.4,-1 1,-1 0.6,0 1,0.45 1,1 0,0.75 -0.8,2.499999 -2.7,2.5 -0.224891,0 -0.95,-0.05 -1.25,-0.2 -0.65,-0.4 -0.7,-0.4 -1.25,0 C 11,18 10.629076,18 10.4,18 8.1999999,18 7.4999999,16.2 7.4999999,15.45 c 0,-0.55 0.4240479,-1.002126 0.9999999,-1 0.5759521,0.0021 1,0.55 1,1 z'
})

export const EmojiHeartEyes_Stroke2_Corner0_Rounded = createSinglePathSVG({
  path: 'M4 12a8 8 0 1 1 16 0 8 8 0 0 1-16 0Zm8-10C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2ZM9.351 12.13c1.898-1.507 2.176-2.95 1.613-3.83a1.524 1.524 0 0 0-1.225-.707 1.562 1.562 0 0 0-1.218.53 1.561 1.561 0 0 0-1.326-.082c-.456.186-.8.588-.91 1.083-.227 1.02.527 2.282 2.826 3.048a.256.256 0 0 0 .24-.043Zm5.538.042c2.299-.766 3.053-2.027 2.826-3.048a1.524 1.524 0 0 0-.91-1.082 1.561 1.561 0 0 0-1.326.081 1.562 1.562 0 0 0-1.217-.53 1.524 1.524 0 0 0-1.226.706c-.563.881-.285 2.325 1.613 3.83.068.054.158.07.24.043Zm1.072 2.38a4 4 0 0 1-7.924 0c-.04-.293.218-.525.514-.499 2.309.206 4.587.206 6.896 0 .296-.026.555.206.514.5Z',
})
